<template>
  <grid-item
      legend="Aprovação de Cadastros"
      icon="user-check" icon-type="fa"
      class=""
      ch-legend="ARREMATANTES QUE ENVIARAM OS DOCUMENTOS E PRECISAM DE ANÁLISE"
      :link="() => $router.push({name: 'arrematantes.aprovacao'})"
  >
    <template>
      <monitor-aprovacao class="dash-monitor-wrapper"/>
    </template>
    <template v-slot:card-footer>
    </template>
  </grid-item>
</template>

<script>
import GridItem from '@/components/dashboard/includes/GridItem'
// import CardItemLink from '@/reuse/card/CardItemLink'
import MonitorAprovacao from "components/arrematante/components/MonitorAprovacao"

export default {
  name: "DashAprovacaoCadastroWrapper",
  mixins: [],
  components: {GridItem, MonitorAprovacao},
  computed: {},
  methods: {}
}
</script>

<style lang="stylus">
.dash-monitor-wrapper {
  position relative

  .menu-lateral-leilao {
    width 150px !important
    min-width 150px !important
    font-size 12px
    background-color #f2f2f2
    padding 10px
    position relative

    .menu-container {
      position sticky
      top 0
      padding-top 10px
    }
  }

  .aprovacao-container .aprovacao-item {
    width 100%
    min-width 100%
  }
}
</style>
