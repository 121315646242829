<template>
  <grid-item
      legend="Origem dos Acessos"
      icon="check" icon-type="fa"
      class=""
      ch-legend="GRÁFICO DE DISPOSITIVOS QUE ACESSAM A PLATAFORMA"
      :link="() => $router.push({name: 'arrematantes.habilitacao'})"
  >
    <template>
      <Comp />
    </template>
    <template v-slot:card-footer>
    </template>
  </grid-item>
</template>

<script>
import GridItem from '@/components/dashboard/includes/GridItem'
// import CardItemLink from '@/reuse/card/CardItemLink'
import Comp from "components/arrematante/components/monitor/OrigemAcesso"

export default {
  name: "DashAprovacaoCadastroWrapper",
  mixins: [],
  components: {GridItem, Comp},
  computed: {},
  methods: {}
}
</script>

<style lang="stylus">
.dash-monitor-wrapper {
  position relative
}
</style>
