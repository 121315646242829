<script>
/* eslint-disable */
import {UInput, USelect} from 'uloc-vue'
import {ErpInput, ErpSField} from 'uloc-vue-plugin-erp'
import CardWidget from "@/reuse/card/Widget"
import CardColumn from "@/reuse/card/Column"
import CardItem from "@/reuse/card/Card"
import CardItemLink from "@/reuse/card/CardItemLink"
import CadastroStats from "@/components/dashboard/components/DashWidgets/CadastroStats"
import Aprovacao from "@/components/dashboard/components/Cadastro/Aprovacao"
import Habilitacao from "@/components/dashboard/components/Cadastro/Habilitacao"
import StatusCadastros from "@/components/dashboard/components/Cadastro/StatusCadastros"
import Origem from "@/components/dashboard/components/Cadastro/Origem"
import {getDashboardData} from "@/domain/estatisticas/services"
import VueGridLayout from 'vue-grid-layout'
import Vue from 'vue'
import AcessoStats from "components/dashboard/components/DashWidgets/AcessoStats"

Vue.component('CadastroStats', CadastroStats)
Vue.component('Aprovacao', Aprovacao)
Vue.component('StatusCadastros', StatusCadastros)
Vue.component('Habilitacao', Habilitacao)
Vue.component('Origem', Origem)
Vue.component('AcessoStats', AcessoStats)

export default {
  name: 'Dashboard1',
  provide: function () {
    return {
      dashboard: this
    }
  },
  mixins: [],
  components: {
    CadastroStats,
    CardItemLink,
    CardItem,
    CardColumn,
    CardWidget,
    ErpInput,
    ErpSField,
    UInput,
    USelect,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  data() {
    return {
      loading: false,
      basicData: null,
      layout: [
        {x: 0, y: 0, w: 6, h: 12, i: 0, minW: 4, minH: 4, component: 'CadastroStats'},
        {x: 6, y: 0, w: 6, h: 12, i: 1, minW: 4, minH: 4, component: 'StatusCadastros'},
        {x: 0, y: 2, w: 6, h: 12, i: 2, minW: 4, minH: 4, component: 'Origem'},
        {x: 6, y: 2, w: 6, h: 12, i: 3, minW: 4, minH: 4, component: 'AcessoStats'},
        {x: 0, y: 3, w: 6, h: 12, i: 4, minW: 4, minH: 4, component: 'Aprovacao'},
        {x: 6, y: 3, w: 6, h: 12, i: 5, minW: 4, minH: 4, component: 'Habilitacao'},
      ],
    }
  },
  computed: {},
  mounted() {
    this.loadBasicData()
    this.$interval && clearInterval(this.$interval)
    this.$interval = setInterval(() => {
      this.loadBasicData(false)
    }, 60000) // @TODO: Mudar para 60s no mínimo. 5s só para testes
  },
  beforeDestroy() {
    this.$interval && clearInterval(this.$interval)
  },
  methods: {
    loadBasicData(enableLoading = true) {
      if (enableLoading) this.loading = true
      getDashboardData()
          .then(response => {
            if (enableLoading) this.loading = false
            this.basicData = response.data
          })
          .catch(error => {
            if (error.data.status === 401) {
              setTimeout(() => this.$router.push('/logout'), 500) // TMP
              return
            }
            this.alertApiError(error)
            this.$interval && clearInterval(this.$interval)
            if (enableLoading) this.loading = false
          })
    }
  },
  meta: {
    title: 'Dashboard',
    name: 'Dashboard'
  }
}
</script>

<template>

        <div class="dash-content">
          <grid-layout
              :layout.sync="layout"
              :col-num="12"
              :row-height="30"
              :is-draggable="true"
              :is-resizable="true"
              :is-mirrored="false"
              :vertical-compact="true"
              :margin="[10, 10]"
              :use-css-transforms="true"
              class="grid-widget"
          >

            <grid-item v-for="item in layout"
                       :x="item.x"
                       :y="item.y"
                       :w="item.w"
                       :minW="item.minW"
                       :h="item.h"
                       :minH="item.minH"
                       :i="item.i"
                       :key="item.i"
                       drag-allow-from=".vue-draggable-handle"
                       drag-ignore-from=".no-drag"
            >
              <component v-bind:is="item.component"/>
            </grid-item>
          </grid-layout>
          <!--        <card-widget>
                    <card-column>
                      <agenda-leiloes/>
                      <tarefas-stats/>
                      <processos />
                      <software-avisos/>
                      <tasks-usuario-stats/>
                    </card-column>
                    <card-column>
                      <acesso-stats/>
                      <cadastro-stats/>
                      <tasks-usuario-mes-atual-stats/>
                    </card-column>
                    <card-column>
                      <software-status/>
                      <financeiro-stats/>
                      <software-logs />
                      <processos-clientes-stats/>
                      <processos-advogados-stats/>
                    </card-column>
                  </card-widget>-->
        </div>
</template>
