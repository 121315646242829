<template>
  <grid-item
      legend="Situação dos Cadastros"
      icon="check" icon-type="fa"
      class=""
      ch-legend="GRÁFICO DOS CADASTROS DE ACORDO O STATUS"
      :link="() => $router.push({name: 'arrematantes.habilitacao'})"
  >
    <template>
      <aprovacao />
    </template>
    <template v-slot:card-footer>
    </template>
  </grid-item>
</template>

<script>
import GridItem from '@/components/dashboard/includes/GridItem'
// import CardItemLink from '@/reuse/card/CardItemLink'
import Aprovacao from "components/arrematante/components/monitor/Aprovacao"

export default {
  name: "DashAprovacaoCadastroWrapper",
  mixins: [],
  components: {GridItem, Aprovacao},
  computed: {},
  methods: {}
}
</script>

<style lang="stylus">
.dash-monitor-wrapper {
  position relative
}
</style>
